import BaseComponent from "lib@/base_component";
import User from "lib@/user";

/**
 * 页面布局的组件
 */
class LayoutCpn extends BaseComponent
{
    constructor(cfg = null){
        super();

        const defaultCfg = {
        }

        this._cfg = {
            ...defaultCfg,
            ...cfg,
        };

        this.navLeftTpl      = null;
        this.timerSearchMenu = null;

        this.init();
    }

    init(){
        Promise.all([
            this._cfg.App.getTpl('layout/nav_left'),
            this._cfg.App.getTpl('layout/default'),
        ])
        .then((res) => {
            this.navLeftTpl = res[0];
            return res[1];
        })
        .then((tpl) => {
            this._cfg.App.res.render(tpl({
                userData: User.getUserData(),
            }));

            this.refreshMenu(User.getUserData('page_list', []));

            layui.use(['element'], function(){
                var element = layui.element;
                element.render();
            });

            $('#fold_bar').on('click', function (event){
                let mySelf    = $(this);
                let foldKey   = 'data-folded';
                let foldVal   = '';
                let iElm      = mySelf.children('i').first();
                let sideElm   = $('.layui-layout-admin>.layui-side');
                let topBarElm = $('#main_top_bar');
                let bodyElm   = $('#main_body');

                iElm.removeClass('my-anim-rotate-half my-anim-rotate-half-reverse');
                // 菜单展开的操作
                if(mySelf.attr(foldKey) == '1'){
                    iElm.addClass('my-anim-rotate-half-reverse');
                    sideElm.show();
                    mySelf.css('left', '200px');
                    topBarElm.css('padding-left', '200px');
                    bodyElm.css('left', '200px');
                }
                // 菜单收起的操作
                else{
                    foldVal = '1';
                    iElm.addClass('my-anim-rotate-half');
                    sideElm.hide();
                    mySelf.css('left', '0px');
                    topBarElm.css('padding-left', '0px');
                    bodyElm.css('left', '0px');
                }

                mySelf.attr(foldKey, foldVal);
                $(window).trigger('resize');
                layui.$(window).trigger('resize');
            });

            $('input[name="menu_search_input"]').on('keyup', (event) => {
                if(this.timerSearchMenu){
                    clearTimeout(this.timerSearchMenu);
                }
                this.timerSearchMenu = setTimeout(() => {
                    let val = $(event.target).val();
                    this.searchMenu(val);
                }, 600);
            });

            $('.layui-side-scroll #search_close').on('click', () => {
                $('input[name="menu_search_input"]').val('');
                this.searchMenu('');
            });
        });

    }

    refreshMenu(pageList){
        $('ul[lay-filter="nav_left"]').html(this.navLeftTpl({
            pageList: pageList,
        }));
    }

    searchMenu(keyword){
        let pageList = User.getUserData('page_list', []);

        if(keyword != ''){
            pageList = this.doSearchMenu(pageList, keyword.toLowerCase());
        }

        this.refreshMenu(pageList);
        this.searchCloseButShow(keyword);
        layui.use(['element'], function(){
            var element = layui.element;
            element.render();
        });
    }

    doSearchMenu(tree, keyword){
        let ret = [];
        for(let i = 0; i < tree.length; i++){
            let item = tree[i].item;

            // 如果是分组的类型 则递归搜索下一级菜单
            if(item.type == 1){
                let sub = this.doSearchMenu(tree[i].children, keyword);
                if(sub.length > 0){
                    ret.push({
                        item: item,
                        children: sub,
                    });
                }
            }
            // 如果是具体的菜单项目 则判断显示的标题是否包含关键字
            else if(item.display_name.toLowerCase().indexOf(keyword) != -1 || item.description.toLowerCase().indexOf(keyword) != -1){
                ret.push(tree[i]);
            }
        }
        return ret;
    }

    searchCloseButShow(keyword){
        if(keyword){
            $('.layui-side-scroll #search_close').show();
        }else{
            $('.layui-side-scroll #search_close').hide();
        }
    }
    
}

export default LayoutCpn;