import SMERouter from 'sme-router';
import User from 'lib@/user';
import artRuntime from 'art-template/lib/runtime';
import tagList from 'lib@/tag_extends/tag';
import { urlEncode, log, getObjProperty, myRound } from 'lib@/util';


/**
 * 平台总入口
 */

const router = new SMERouter('main_page', 'html5');

App.router = router;

// 为 art-template 设置相关属性
artRuntime.log            = log;
artRuntime.getObjProperty = getObjProperty;
artRuntime.ceil           = Math.ceil;
artRuntime.myRound        = myRound;

// 为 art-template 设置tag对应的方法
for(let k in tagList){
    artRuntime[k] = tagList[k];
}

// 超链接支持路由
$('body').on('click', 'a[route-url]', function (event){
    let elm = $(this);
    event.preventDefault();
    App.router.go(elm.attr('href'));
});

$('body').on('click', 'a[route-goback]', function (event){
    event.preventDefault();
    App.router.back();
});

// 设置了 lay-filter="get-query" 的提交操作，改为 router 的 url 跳转
layui.form.on('submit(get-query)', (data) => {
    let action = $(data.form).attr('action') || (App.req.url.split('?'))[0];

    App.router.go(`${action}?${urlEncode(data.field)}`);

    return false;
});

/**
 * 最后一个显示的页面对象
 */
let lastPage = null;

function showPage(page){
    if(lastPage){
        lastPage.leave();
    }

    if(!page){
        return;
    }

    page.enter();

    lastPage = page;
}

router.route(
    '/login',
    (req, res, next) => {
        
        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('login').then((page) => {
            showPage(page);
        });
    }
);

router.route(
    '/record/login',
    (req, res, next) => {

        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('reportpc/reportlogin').then((page) => {
            showPage(page);
        });
    }
);

router.route(
    '/404',
    (req, res, next) => {

        App.req  = req;
        App.res  = res;
        App.next = next;

        App.getPage('error404').then((page) => {
            showPage(page);
        });
    }
);



router.route(
    '*',
    (req, res, next) => {
        let url = (req.url.split('?'))[0];
        let url_prefix = url.slice(0,9);
        let admin_role= url_prefix == '/reportpc' ?  User.getThirdUserData() : User.getUserData().admin_role;
        let defaultRoute = url_prefix == '/reportpc' ? url : User.getDefaultRoute();
        if(typeof(admin_role) == 'undefined' || admin_role == '-1'){
            if(/^(\/|\/index)(\?.*){0,1}$/.test(url)){
                if(defaultRoute != ''){
                    router.redirect(defaultRoute);
                }
                else{
                    router.go('/login');
    
                }
                return;
            }
            // 没有默认路由表示未登录
            else if(!defaultRoute){
                router.go('/login');
                return;
            }
        }

        App.req  = req;
        App.res  = res;
        App.next = next;
        App.outputLayout().then(() => {
            return App.getPage(url);
        })
        .then((page) => {
            showPage(page);
        });
    }
);