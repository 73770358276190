/**
 * 组件类的基础类
 */

 class BaseComponent
 {
     constructor() {
 
     }
 
     
 }
 
 export default BaseComponent;