{{if 'whole_line' in attr}}
<div class="layui-form-item">
    {{if attr.label_title}}
        <label class="layui-form-label">{{ attr.label_title }}</label>
    {{/if}}
    <div class="layui-input-block">
{{/if}}
{{if 'in_line' in attr}}
<div class="layui-inline">
    {{if attr.label_title}}
        <label class="layui-form-label">{{ attr.label_title }}</label>
    {{/if}}
    <div class="layui-input-inline">
{{/if}}
{{block 'line'}}{{/block}}
{{if 'whole_line' in attr}}
    </div>
</div>
{{/if}}
{{if 'in_line' in attr}}
    </div>
</div>
{{/if}}