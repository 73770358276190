/**
 * 工具函数
 */

/**
 * 打印日志的函数 正式环境下不打印
 * @param {any} data 
 */
export function log(data) {
    if(process.env.NODE_ENV != 'production'){
        console.log(data);
    }
}

/**
 * 判断参数是否为空对象的方法
 * @param {Object} obj 
 * @returns boolean
 */
export function isEmptyObj(obj){
    return (obj && Object.keys(obj).length > 0) ? false : true;
}

/**
 * 判断嵌套的属性，是否存在的函数
 * @param {object} obj          待判断的对象
 * @param {string} propertyPath 嵌套的属性链
 * @returns boolean
 */
export function checkObjProperty (obj, propertyPath) {
    if(!obj || obj == 'undefined' || obj == undefined){
        return false;
    }
    let arr = propertyPath.split(".");
    let tempObj = obj;
    for(let i = 0; i < arr.length; i++)
    {
        if(tempObj[arr[i]] == undefined){
            return false;
        }else{
            tempObj = tempObj[arr[i]];
        }
    }

    return true;
}

/**
 * 获取对象属性的值的函数
 * @param {object} obj          待判断的对象
 * @param {string} propertyPath 嵌套的属性链
 * @param {any}    defaultVal   属性不存在时的返回值
 * @returns any
 */
export function getObjProperty(obj, propertyPath, defaultVal = undefined) {
    if(checkObjProperty(obj, propertyPath)){
        let arr = propertyPath.split(".");
        let tempObj = obj;
        for(let i = 0; i < arr.length; i++){
            tempObj = tempObj[arr[i]];
        }
        return tempObj;
    }
    else{
        return defaultVal;
    }
}

/**
 * 判断变量是否为空值的方法
 * @param {any} val 
 * @returns boolean
 */
export function hasValue(val) {
    return (val != null && val != undefined && val != '') ? true : false;
}

/**
 * 将对象中，不为空值的属性，进行 url encoe 编码的函数
 * @param {object} obj 
 * @returns {string}
 */
export function urlEncode(obj) {
    let params = [];

    for(let k in obj){
        if(hasValue(obj[k])){
            params.push(`${k}=${encodeURIComponent(obj[k])}`);
        }
    }

    return params.join('&');
}

/**
 * 获取原始dom的
 * @param {string}} id 
 * @returns dom
 */
export function getDom(id) {
    return document.getElementById(id);
}

/**
 * 对原始值进行指定位数的四舍五入的函数
 * @param {number} val 原始值
 * @param {integer} bit 希望保留的小数位数
 * @returns 相应精度的值
 */
export function myRound(val, bit){
    let t = Math.pow(10, bit);
    return Math.round(val * t) / t;
}

/**
 * 判断uuid的类型的函数
 * @param {string} uuid 
 * @returns txv腾讯视频vid hxbl呼吸部落的uuid 空字符串判断失败
 */
export function getVideoType(uuid){
    // 腾讯视频 平台的 vid txv_xxxx txv_这个前缀是我自己加的
    if(/txv_\w+/.test(uuid)){
        return 'txv';
    }

    // 上传到 呼吸部落七牛云存储的视频编号 匹配 477a3839-5056-26c3-8e30-3909bfbcb146
    if(/(\w{8}\-\w{4}\-\w{4}\-\w{4}\-\w{12})/.test(uuid)){
        return 'hxbl';
    }

    return '';
}

/**
 * 获取真实腾讯视频 vid 的函数
 * @param {string} vid 
 * @returns 没有txv_前缀的 vid
 */
export function getPureTxvVid(vid){
    if(/txv_\w+/.test(vid)){
        vid = vid.replace('txv_', '');
    }

    return vid;
}

/**
 * 监听窗口大小变化的事件，并设置取消监听的条件
 * @param {function} callbackFun 回调函数
 * @param {function} offFun      取消监听的条件函数 返回true时，取消监听
 * @returns void
 */
export function windowResizeAndOff(callbackFun, offFun) {
    let tmpFun = () => {
        if(offFun() != true){
            callbackFun();
        }
        else{
            $(window).off('resize', tmpFun);
        }
    };

    $(window).on('resize', tmpFun);
}