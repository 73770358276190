import testTagTpl from 'tpl@/tag/test_tag.art';
import selectTpl from 'tpl@/tag/select.art';
import textTpl from 'tpl@/tag/text.art';
import numberTpl from 'tpl@/tag/number.art';
import radioTpl from 'tpl@/tag/radio.art';
import textareaTpl from 'tpl@/tag/textarea.art';

function get_tpl_data(attr, dataMap){
    return {
        attr: attr,
        data: dataMap
    };
}

export default {
    /**
     * 演示用的标签
     * @param {Object} attr 属性的键值对
     * @param {Object} data 导入模版的数据
     */
    testTag: function (attr, data){
        return testTagTpl(get_tpl_data(attr, data));
    },

    /**
     * 输出 select 的标签
     * @param {Object} attr 属性的键值对
     * @param {Object} data 导入模版的数据
     */
    select: function (attr, data){
        return selectTpl(get_tpl_data(attr, data));
    },

    /**
     * 输出 text 的标签
     * @param {Object} attr 属性的键值对
     * @param {Object} data 导入模版的数据
     */
    text: function (attr, data){
        return textTpl(get_tpl_data(attr, data));
    },

    /**
     * 输出 number 的标签
     * @param {Object} attr 属性的键值对
     * @param {Object} data 导入模版的数据
     */
     number: function (attr, data){
        return numberTpl(get_tpl_data(attr, data));
    },

    

    /**
     * 输出 radio 的标签
     * @param {Object} attr 属性的键值对
     * @param {Object} data 导入模版的数据
     */
    radio: function (attr, data){
        return radioTpl(get_tpl_data(attr, data));
    },

    /**
     * 输出 textarea 的标签
     * @param {Object} attr 属性的键值对
     * @param {Object} data 导入模版的数据
     */
    textarea: function (attr, data){
        return textareaTpl(get_tpl_data(attr, data));
    }
}