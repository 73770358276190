/**
 * 配置数据获取类
 */
//读取env配置文件 加载地址
import env from "../../env";
let cfg = {
    token_header_key: 'Authorization',
    txv_url         : 'https://v.qq.com/txp/iframe/player.html?vid=', // 腾讯视频的播放地址
    ...env,
};
export default new Proxy(cfg, {
    get: (target, propKey) => {
        if(propKey in target){
            return target[propKey];
        }
        else{
            throw new ReferenceError('property '+ propKey +' dose not exists');
        }
    }
});