import {isEmptyObj} from "lib@/util";

/**
 * 当前管理用户的类
 */
class User {
    constructor() {
        this._userData                = null;
        this._flatPermissionList      = {};
        this._localStorageUserDataKey = 'admin_user_data_json';
    }

    get pureUserToken() {
        let token = this.getUserData('token', '');
        token = token.replace(/Bearer /,'');
        return token;
    }

    get userToken() {
        return this.getUserData('token', '');
    }

    set userToken(token) {
        let userData = this.getUserData();
        userData.token = token;
        this.saveUserData(userData);
    }

    set thirdUserToken(token) {
        let userData = this.getThirdUserData();
        if (typeof userData.token != 'undefined' && userData.token != '')
        {
            userData.token = token;
            this.saveThirdUserData(userData);
        }
    }

    saveUserData(userData) {
        this._userData = userData;
        localStorage.removeItem(this._localStorageUserDataKey);
        localStorage.setItem(
            this._localStorageUserDataKey,
            JSON.stringify(userData),
        );
        return true;
    }

    saveThirdUserData(userData,userDatakey,key = 'third_user_data_key') {
        this._userData = userData;

        userDatakey =  userDatakey ? userDatakey : localStorage.getItem(key) || '';

        localStorage.removeItem(userDatakey);
        localStorage.setItem(
            userDatakey,
            JSON.stringify(userData),
        );
        localStorage.setItem(
            key,
            userDatakey,
        );
        return true;
    }

    getLocalStorageItem(key = 'third_user_data_key')
    {
        return localStorage.getItem(key);
    }

    clearUserData() {
        this._flatPermissionList = {};
        return this.saveUserData({});
    }

    clearThirdUserData(userDatakey = '',key = 'third_user_data_key') {
        this._flatPermissionList = {};
        let third_user_data_key = localStorage.getItem(key) || ''
        localStorage.removeItem(third_user_data_key);
        localStorage.removeItem(key);
        return true;
    }

    getUserData(dataKey = '', defaultData = '') {
        if (!this._userData) {
            let jsonData = localStorage.getItem(this._localStorageUserDataKey) || '';
            this._userData = jsonData ? JSON.parse(jsonData) : {};
        }

        if (dataKey == '') {
            return this._userData;
        }

        return this._userData[dataKey] || defaultData;
    }

    getThirdUserData(dataKey = '', defaultData = '',key = 'third_user_data_key') {
        let _localStorageThirdUserDataKey =  localStorage.getItem(key) || '';

        let jsonData = localStorage.getItem(_localStorageThirdUserDataKey) || '';
        this._userData = jsonData ? JSON.parse(jsonData) : {};
        if (dataKey == '') {
            return this._userData;
        }

        return this._userData[dataKey] || defaultData;
    }

    getDefaultRoute() {
        let pageList = this.getUserData('page_list', []);
        let permission = this._getFirstPermission(pageList);
        return permission.route || '';
    }

    getFlatPermissionList() {
        if(!isEmptyObj(this._flatPermissionList)){
            return this._flatPermissionList;
        }

        let pageStack = [];
        let pageList = [];

        pageStack.push(this.getUserData('page_list', []));

        while(pageList = pageStack.pop()){
            for(const page of pageList){
                this._flatPermissionList[page.item.id] = page.item;

                if(page.children.length > 0){
                    pageStack.push(page.children);
                }
            }
        }

        return this._flatPermissionList;
    }

    getCheckoutPermission(permission=''){
        if(permission != ''){
            let permission_list = this.getUserData('permission_list'); 
            if(permission_list.length >0){
                return permission_list.find(item => {
                    return item  ==  permission
                })  !== undefined;
            }
            return false;
        }
        
        return false;
    }

    _getFirstPermission(pageList) {
        if (pageList.length < 1) {
            return {};
        }

        for (let k in pageList) {
            let treeNode = pageList[k];

            if (treeNode.item.type == 2) {
                return treeNode.item;
            }

            let tmp = this._getFirstPermission(treeNode.children || []);

            if (!isEmptyObj(tmp)) {
                return tmp;
            }
        }

        return {};
    }
}

export default new User();